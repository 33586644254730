import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import theme from "../theme"
import { PaddingContainer, LargeContainer } from "../styled"

const Footer = () => (
	<Section>
		<LargeContainer>
			<FooterContainer>
				<Left>
					<div>
						<NavLink to="/terms-and-conditions">Terms of Use</NavLink>
					</div>
					<div>
						<NavLink to="/privacy-policy">Privacy Policy</NavLink>
					</div>
					<div>
					<NavLink to="/accessibility">Accessibility</NavLink>
					</div>
				</Left>
				<Center>
					<h6>Keep in Touch</h6>
					<div className="social">
						<a href="mailto: info@kosmiqueapp.com" target="_blank">
							<StaticImage
								src="../images/icons/email.png"
								width={24}
								quality={95}
								formats={["auto", "webp", "avif"]}
								alt="Email"
							/>
						</a>
						<div style={{width:10}} />
						{/* <StaticImage
							src="../images/icons/facebook.png"
							width={40}
							quality={95}
							formats={["auto", "webp", "avif"]}
							alt="Facebook"
						/> */}
						<div style={{width:10}} />
						<a href="https://www.instagram.com/kosmiqueapp/" target="_blank">
							<StaticImage
								src="../images/icons/instagram.png"
								width={30}
								quality={95}
								formats={["auto", "webp", "avif"]}
								alt="Instagram"
							/>
						</a>
					</div>
					<div style={{textAlign: "center", fontSize:'.8rem'}}><a href="mailto:info@kosmiqueapp.com">info@kosmiqueapp.com</a></div>
					<div className="copyright">2021 - Kosmique. All rghts reserved.</div>
				</Center>
				<Right>
					<StaticImage
						src="../images//bigX.png"
						width={100}
						quality={95}
						formats={["auto", "webp", "avif"]}
						alt="X"
						className="x"
					/>
				</Right>
			</FooterContainer>
		</LargeContainer>
	</Section>
)

export default Footer

const mobileFooterColumnStyle = css`

		width:100%;
		justify-content:center;
		text-align:center;
		margin-bottom:20px;
`

const Section = styled.section`
	background-color:${theme.colors.black};
`
const FooterContainer = styled(PaddingContainer)`
	width:100%;
	display:flex;
	justify-content: space-between;
	padding-top:30px;
	padding-bottom:30px;
	width: 100%;
	justify-content:center;
	@media(max-width:645px) {
		flex-direction:column;
	}
`

const NavLink = styled(Link)`
	color: ${theme.colors.white};
	text-decoration: none;
	text-transform: uppercase;
	font-family:"Maax";
	font-size:.7rem;
`

const Left = styled.div`
	width:33.33333%;
	justify-content:center;
	display:flex;
	flex-direction:column;
	>div {
		margin-bottom:.5rem;
	}
	@media(max-width:645px) {
		${mobileFooterColumnStyle}
	}
`

const Center = styled.div`
	width:33.33333%;
	justify-content:center;
	h6 {
		color:${theme.colors.pink};
		font-size:28px;
		text-align:center;
		margin-bottom:.3rem;
		margin-top:.2rem;
	}
	.social {
		display:flex;
		align-items:center;
		justify-content: center;
		a {
			display:flex;
		}
	}
	.copyright {
		text-align:center;
		font-size:10px;
		color:${theme.colors.white};
		margin-top:.6rem;
	}
	@media(max-width:645px) {
		${mobileFooterColumnStyle}
	}
`

const Right = styled.div`
	width:33.33333%;
	display:flex;
	justify-content:flex-end;
	.x {
		margin-top:-20px;
		transform: scale(.8);
    transform-origin: right;
	}
	@media(max-width:645px) {
		${mobileFooterColumnStyle}
		margin-bottom:0;
	}
`